import React from 'react'
import Nav from './comp/nav'

export default function contact() {
  return (
    <>
    <Nav></Nav>
    <div>CONTACT</div>
    </>
  )
}
