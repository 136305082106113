import React from 'react'
import Nav from './comp/nav'

export default function about() {
  return (
    <>   
    <Nav></Nav>
     <div>ABOUT</div>
    </>

  )
}
