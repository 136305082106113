import logo from './logo.svg';
import Nav from './comp/nav'
import Leftside from './comp/leftside'
import Rightside from './comp/rightside'
import Footer from './comp/footer'

import './App.css';
;

function App() {
  return (
   <>  
   <Nav></Nav>
   <div class="row">
    <div class="col-sm-3"><Leftside></Leftside></div>
    <div class="col-sm-6"></div>
    <div class="col-sm-3"><Rightside></Rightside></div>
   </div>
   <Footer></Footer>
   </>
  );
}

export default App;
