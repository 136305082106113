import React from 'react'

export default function () {
  return (
    <>
    <div>
    <div class="container bg-light text-center">
    <span class="text-dark ">Place sticky footer content here.</span>
     </div>
    </div>
    </>
  )
}
